import "./App.css";

function App() {
  return (
    <div className="container md:mx-auto text-center">
      <h2 className="text-lg font-bold">Cloudflare Page demo</h2>
    </div>
  );
}

export default App;
